<template>
  <div class="middle-box text-center animated fadeInDown">
        <div>
          <img src="@public/img/eurofiscalis/logo.png" alt="Eurofiscalis">
        </div>
        <h3 class="font-bold">Maintenance on going...</h3>

        <div class="error-desc">
          Sorry but we are currently working on the application. Please come back in few minutes.
        </div>
    </div>

</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';

@Component({})
export default class Maintenance extends mixins(GenericPage) {
 
}
</script>